.App {
  text-align: center;
}

.logo-spin {
  height: 40vmin;
  /*pointer-events: none;*/
}

@media (prefers-reduced-motion: no-preference) {
  .logo-spin {
    animation: logo-spin infinite 15s linear;
  }
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: monospace;
}

.App-link {
  color: #61dafb;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blinking-cursor {
  font-weight: 800;
  font-size: 30px;
  color: white;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-ms-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-o-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

.social {
  margin-right: 1vw;
}

.zoom {
  transition: transform .5s; /* Animation */
}

.zoom:hover {
  transform: scale(1.3); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.typed {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing step-end alternate;
  animation-delay: 2s;
  animation-duration: 2s;
  animation-timing-function: steps(30, end);
  animation-fill-mode: forwards;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.greeting {
  display: flex;
}